.back-arrow {
    cursor: pointer;
    float: left;
    font-size: 32px;
    padding: 10px 0 0 10px;
    position: absolute !important;
}

.currency-selector .MuiAutocomplete-popper {
    margin-top: 56px;
    margin-left: auto;
    width: 100% !important;
}

.settings-panel .MuiDrawer-paper {
    max-width: 500px;
    width: 80%;
}

@media only screen and (min-width: 768px) {
    .MuiDrawer-paper {
        width: 50%;
    }
}

.MuiDrawer-paper {
    width: 80%;
}

.MuiListItem-root img,
.MuiSelect-select img{
    height: 50px;
}

@media only screen and (max-width: 500px) {
    .MuiListItem-root img,
    .MuiSelect-select img{
        height: 40px;
    }
}

.settings-panel .MuiDataGrid-root {
    width: 90%;
    margin: auto;
}

.settings-panel .MuiFormControl-root,
.settings-panel .MuiInputBase-root,
.dataSource {
    max-width: 375px;
}

.settings-panel input {
    height: 38px;
    width: 100%;
}

.settings-title {
    padding-top: 10px;
}